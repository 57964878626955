import family from '../img/family.svg';
import ice from '../img/ice.svg';
import disease from '../img/disease.svg';

export const dataCases = [
    {
        id: 1,
        title: 'Непредвиденный случай',
        description: 'Василий собирался на концерт своей любимой группы. Но по дороге подскользнулся на гололёде, и срочно отправился в травмпункт. Благодаря страховке на возврат, Василий смог без труда вернуть деньги за пропущенный концерт.',
        imageUrl: ice,
        child: false
    },
    {
        id: 2,
        title: 'Заболел ребенок',
        description: 'Петр и его семья планировали сходить в музей. Но за день до похода у дочери выявили ветрянку.  Благодаря страховке на возврат, семья смогла компенсировать затраты на отмененную экскурсию',
        imageUrl: family,
        child: true

    },
    {
        id: 3,
        title: 'Простуда',
        description: 'Анна приобрела билет на выставку современного искусства. Но накануне мероприятия простудилась. Благодаря страховке на возврат, Анна смогла вернуть деньги и позаботиться о своём здоровье.',
        imageUrl: disease,
        child: false
    }
];
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import Slider from "../landing/components/Slider";
import Navbar from "../../components/navbar/Navbar";
import Profile from "../profile/Profile";
import ParticipantForm from "../../forms/participantForm";
import './Order.css';
import Button from "../../UI/Button";
import Footer from "../../components/footer/Footer";
import Ticket from "./Ticket";

interface Event {
    id: number;
    name: string;
    location: string;
    images: string[];
    description: string;
    price: string;
}

interface Refund {
    id: number;
    tickets: number[];
    amount: number;
    status: string;
    created_at: string;
    updated_at: string;
    reason: string;
    comment: string;
    bonus_refund: boolean;
    order: number;
}

interface Order {
    id?: number;
    tickets: Ticket[];
    email: string;
    price?: number;
    payment_link?: string;
    status?: string;
    refund?: Refund;
}

const Order: React.FC = () => {
    const location = useLocation();
    const {formData, eventId} = location.state as { formData: any; eventId: number };
    const [profile, setProfile] = useState<Profile | null>(null);
    const [event, setEvent] = useState<Event | null>(null);
    const [insurance, setInsurance] = useState(false);

    const getProfile = async () => {
        const host = 'https://api.ins-russpass.ru'
        const access = localStorage.getItem('access')
        const response = await axios.get<Profile>(`${host}/users/me`,
            {headers: {"Authorization": `Bearer ${access}`}});
        setProfile(response.data);
    };
    const getEvent = async () => {
        const host = 'https://api.ins-russpass.ru'
        const response = await axios.get<Event>(`${host}/trips/${eventId}`);
        setEvent(response.data);
    };
    const postTickets = async () => {
        if (event == null) return;
        const ticket: Ticket = {
            event: event?.id,
            first_name: (profile != null) ? profile.name : 'Test',
            last_name: 'Test',
            middle_name: 'Test',
            birth_date: '1999-01-01',
            type: 'adult',
            is_insured: insurance
        }
        const order: Order = {
            tickets: [ticket],
            email: (profile != null) ? profile.email : 'test@gmail.com'
        }
        const access = localStorage.getItem('access')
        const host = 'https://api.ins-russpass.ru'
        return await axios.post<Order>(`${host}/payments`, order,
            {headers: {"Authorization": `Bearer ${access}`}});
    }

    useEffect(() => {
        getProfile().then();
        getEvent().then();
    }, [eventId]);

    if (!event) {
        return <div>Loading...</div>;
    }

    const handleButtonClick = () => {
        postTickets().then(response => {
            if (response == null) return;
            if (response.data.payment_link == null) return;
            window.open(response.data.payment_link, '_self');
        });
    };

    const calculatePrice = (
        adultCount: number,
        childCount: number,
        ticketPrice: number
    ): number => {
        return adultCount * ticketPrice + childCount * ticketPrice / 2;
    }

    const price = calculatePrice(formData.adults, formData.children, Number(event.price));
    const total = insurance ? Number(price) + Number(price / 10) : Number(price);

    const peopleAmount = Number(formData.adults) + Number(formData.children);

    return (
        <div>
            <Navbar/>
            <div className="event_details_text">
                <p className='bread'>Главная / События / {event.name}</p>
                <img src={`https://api.ins-russpass.ru${event.images[0]}`} alt="Background" className='preview'/>
                <h2 className='event_name'>{event.name}</h2>
                <h4 className='event_location'>{event.location}</h4>
                <h4 className='event_adults'>Взрослых: {formData.adults}</h4>
                <h4 className='event_children'>Детских: {formData.children}</h4>
            </div>
            <form>
                <ParticipantForm adultsCount={formData.adults} childrenCount={formData.children}/>
                <div className='switch_div'>
                    <div className="onoffswitch">
                        <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch"
                               checked={insurance} onChange={() => setInsurance(!insurance)}/>
                        <label className="onoffswitch-label" htmlFor="myonoffswitch"></label>
                    </div>
                    <h4 className='switch_text'>Страховка ({Number(price / 10)} ₽)</h4>
                </div>
                <div className='insurance-container-checkbox'>
                    {
                        Array.from({length: peopleAmount}, (_, index) => (
                                <div key={`ticket-${index}`} className='checkbox-container'>

                                    <input
                                        type="checkbox"
                                        checked={insurance}
                                    />
                                    <label className="text_checkbox">
                                        {`Билет ${index + 1}`}
                                    </label>
                                </div>
                            )
                        )
                    }
                </div>
                <Button text={`Оплатить (${total} ₽)`} width="90%" backgroundColor="#FFCF08"
                        onClick={handleButtonClick}/>
                <div className="text">
                    <p>Нажимая на кнопку, вы даете согласие на обработку персональных данных, а также принимаете условия
                        пользовательского соглашения, политики конфиденциальности и публичной оферты</p>
                </div>
            </form>
            <Slider child={(formData.children != 0)}/>
            <br/>
            <br/>
            <Footer/>
        </div>
    );
};

export default Order;


import React from 'react';
import styles from './Footer.module.css';
import { FaTelegram, FaVk, FaInstagram, FaOdnoklassniki } from "react-icons/fa";
import { ReactComponent as FooterI } from "./footer.svg";

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainerUP}>
                <div className={styles.footerContainerUPLeft}>
                    <h2>Контакты</h2>
                    <br/>
                    <p>8 (800) 555-35-35</p>
                    <p>press@welcome.moscow</p>
                    <br/>
                    <h2>О проекте</h2>
                    <h2>Вход для партнеров</h2>
                    <FaVk/>
                </div>
                <div className={styles.footerContainerDown}>
                    <FooterI/>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cl from './User.module.css';

interface User {
    name: string;
    email: string;
    bonus: number;
}

const User: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);

    const getUser = async () => {
        const host = 'https://api.ins-russpass.ru'
        const access = localStorage.getItem('access')
        const response = await axios.get<User>(`${host}/users/me`,
            { headers: {"Authorization" : `Bearer ${access}`} });
        setUser(response.data);
    };

    useEffect(() => {
        getUser();
    }, []);

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className={cl.user}>
            <div className={cl.user_img}>
            </div>
            <h1>{user.name}</h1>
            <h3>{user.email}</h3>
            <p>Бонусы: {user.bonus}</p>
        </div>
    );
};

export default User;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Order from "../order/Order";
import cl from './Profile.module.css';
import Navbar from "../../components/navbar/Navbar";
import {ReactComponent as Pasport } from "./img/pasport.svg";
import {ReactComponent as Path} from "./img/path.svg";
import { ReactComponent as Qr } from "./img/qr.svg";
import { ReactComponent as Contacts } from "./components/contacts.svg";
import { ReactComponent as Info } from "./img/info.svg";
import { ReactComponent as Review } from "./img/review.svg";
import User from "./components/User";
import Footer from "../../components/footer/Footer";
import Button from "../../UI/Button";

interface Trip {
    id: number;
    name: string;
    location: string;
    start_date:string;
    image: string;
}
interface Profile {
    id: number;
    email: string;
    name: string;
}

const Profile: React.FC = () => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState<Profile | null>(null);
    const [orders, setOrders] = useState<Order[]>([]);
    const [trips, setTrips] = useState<Trip[]>([]);

    const getProfile = async () => {
        const host = 'https://api.ins-russpass.ru'
        const access = localStorage.getItem('access')
        const response = await axios.get<Profile>(`${host}/users/me`,
            { headers: {"Authorization" : `Bearer ${access}`} });
        setProfile(response.data);
        console.log(response.data)
    };
    const getOrders = async () => {
        const host = 'https://api.ins-russpass.ru';
        const access = localStorage.getItem('access');
        const response = await axios.get<Order[]>(`${host}/payments`,
            { headers: {"Authorization" : `Bearer ${access}`} });
        setOrders(response.data);
        console.log(response.data)
    };

    const getTrips = async () => {
        const host = 'https://api.ins-russpass.ru';
        const access = localStorage.getItem('access');
        const response = await axios.get<Trip[]>(`${host}/trips`,
            { headers: {"Authorization" : `Bearer ${access}`} });
        if (response.data.length > 0) {
            setTrips([response.data[response.data.length - 1]]);
        }
    }

    useEffect(() => {
        getProfile();
        getOrders();
        getTrips();
    }, []);

    function checkInsurance(): boolean {
        if (orders.length == 0) return false;
        console.log(orders[orders.length-1].tickets)
        orders[orders.length-1].tickets.forEach(function (ticket) {
            if (ticket.is_insured) return true;
        });
        return false;
    }
    function onClickInsurance() {
        navigate(`/insurance/${orders[orders.length-1].id}`);
    }

    if (!profile) {
        return <div>Loading...</div>;
    }

    function showButton() {
        // ToDo: проверить условие
        return checkInsurance() ? (
            <Button text="Страховка" width="120px" onClick={onClickInsurance} margin="10px auto"/>
        ) : <Button text="Страховка" width="120px" onClick={onClickInsurance} margin="10px auto"/>
    }
    let lastOrder = (orders.length > 0) ? (
        <div className={cl.last_order} key={orders[orders.length-1].id}>
            <h2>Последний заказ</h2>
            <h3>Заказ №{orders[orders.length-1].id}</h3>
            {showButton()}
        </div>
    ) : null;

    return (
        <div className={cl.profile_events}>
            <div className={cl.navbar}>
                <Navbar/>
            </div>
            <div className={cl.path}>
                <Path/>
            </div>
            <User/>
            {lastOrder}
            <div className={cl.profile_event}>
                <div className={cl.trip}>
                    {/*<img src={trips[trips.length - 1].image} alt=""/>*/}
                    {trips.length > 0 && (
                        <div key={trips[trips.length - 1].id}>
                            <p>{trips[trips.length - 1].name}</p>
                            <p>Место: {trips[trips.length - 1].location}</p>
                            <p>Дата: {trips[trips.length - 1].start_date}</p>
                        </div>)}
                </div>
            </div>
            <div className={cl.buttons}>
                <Button text="Страховой случай" width="180px" margin="0 10px" />
                <Button text="Открыть чек" width="180px"/>
                <Button text="Скачать все билеты" width="380px"/>
            </div>
            <div className={cl.pasport}>
                <Pasport/>
            </div>
            <div className={cl.trips_qr}>
                <h2>Детали заказа</h2>
                <Qr/>
            </div>
            <div className={cl.contacts}>
                <Contacts/>
                <Info/>
                <Review/>
            </div>
            <Footer/>
        </div>
    );
};

export default Profile;

import React, {useState} from 'react';


interface PersonFormProps {
    label: string;
}

const PersonForm: React.FC<PersonFormProps> = ({label}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [birthDate, setBirthDate] = useState('');

    return (
        <>
            <h4>{label}</h4>
            <input
                type="text"
                placeholder="Фамилия"
                value={firstName}
                required
                onChange={e => setFirstName(e.target.value)}
            />
            <input
                type="text"
                placeholder="Имя"
                value={lastName}
                required
                onChange={e => setLastName(e.target.value)}
            />
            <input
                type="text"
                placeholder="Отчество"
                value={patronymic}
                required
                onChange={e => setPatronymic(e.target.value)}
            />
            <input
                type="date"
                placeholder="Дата рождения"
                value={birthDate}
                required
                onChange={e => setBirthDate(e.target.value)}
            />
            <hr/>
        </>
    );
};

interface ParticipantFormProps {
    adultsCount: number;
    childrenCount: number;
}

const ParticipantForm: React.FC<ParticipantFormProps> = ({adultsCount, childrenCount}) => {
    console.log(adultsCount);
    console.log(childrenCount);
    return (
        <>
            {Array.from({ length: adultsCount }, (_, index) => (
                <PersonForm key={`adult-${index}`} label="Взрослый" />
            ))}
            {Array.from({ length: childrenCount }, (_, index) => (
                <PersonForm key={`child-${index}`} label="Ребенок (12-16 лет)" />
            ))}
        </>
    );
};


export default ParticipantForm;
import React from 'react';
import './StepComponent.css';

interface Step {
    label: string;
    isCompleted: boolean;
}

interface Props {
    steps: Step[];
}

const StepComponent: React.FC<Props> = ({ steps }) => {
    return (
        <div className="step-container">
            {steps.map((step, index) => (
                <div key={index} className={`step ${step.isCompleted ? 'completed' : ''}`}>
                    <div className="step-number">|</div>
                    <div className="step-label">{step.label}</div>
                </div>
            ))}
        </div>
    );
};

export default StepComponent;

import React from 'react';
import cl from "./Tickets.module.css";
import Navbar from "../../components/navbar/Navbar";
import { ReactComponent as Path } from "./img/path.svg";
import { ReactComponent as Place } from "./img/place.svg";
import { ReactComponent as Info } from "./img/info.svg";
import { ReactComponent as Russpass } from "./img/russpass.svg";
import { ReactComponent as User1 } from "./img/user1.svg";
import { ReactComponent as User2 } from "./img/user2.svg";
import { ReactComponent as User3 } from "./img/user3.svg";
import { ReactComponent as Pushkin } from "./img/pushkin.svg";
import { ReactComponent as Promo } from "./img/promo.svg";
import { ReactComponent as WhyIns } from "./img/whyins.svg";
import { ReactComponent as ToBuy  } from "./img/tobuy.svg";
import { ReactComponent as Map } from "./img/map.svg";
import { ReactComponent as Footer } from "./img/footer.svg";

const Tickets = () => {
    return (
        <div className={cl.tickets}>
            <div className={cl.navbar}>
                <Navbar/>
            </div>
            <div className={cl.path}>
                <Path/>
            </div>
            <div className={cl.places}>
                <Place/>
            </div>
            <div className={cl.info}>
                <Info/>
            </div>
            <div className={cl.russpass}>
                <Russpass/>
            </div>
            <div className={cl.user}>
                <User1/>
            </div>
            <div className={cl.user}>
                <User2/>
            </div>
            <div className={cl.user}>
                <User3/>
            </div>
            <div className={cl.pushkin}>
                <Pushkin/>
            </div>
            <div className={cl.promo}>
                <Promo/>
            </div>
            <div className={cl.whyins}>
                <WhyIns/>
            </div>
            <div className={cl.toBuy}>
                <ToBuy/>
            </div>
            <div className={cl.footer}>
                <Footer/>
            </div>
        </div>
    );
};

export default Tickets;
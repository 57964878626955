import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {MdAccessTime, MdDateRange, MdEvent, MdLocationOn, MdPlace} from "react-icons/md";
import axios from 'axios';
import './EventForm.css';
import Button from "../../UI/Button";
import './EventDetails.css';
import {ReactComponent as Audio} from "./audio.svg";
import {ReactComponent as Exc} from "./exc.svg";
import {ReactComponent as Interes} from "./interes.svg";
import {ReactComponent as Rait} from "./rait.svg";
import {ReactComponent as Kreml} from "./kreml.svg";
import {ReactComponent as Ins} from "./ins.svg";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import cl from "./Events.module.css";
import Slider from "../landing/components/Slider";

interface Event {
    id: number;
    name: string;
    location: string;
    images: string[];
    description: string;
    price: string;
}


interface EventFormProps {
    eventId: number;
    onSubmit: (data: any) => void;
}


const EventForm: React.FC<EventFormProps> = ({onSubmit}) => {
    const [
        formData,
        setFormData
    ] = useState({
        date: '',
        time: '',
        adults: 0,
        children: 0
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="date"
                name="date"
                placeholder="Дата"
                required
                value={formData.date}
                onChange={handleChange}
            />
            <input
                className="time"
                type="time"
                name="time"
                value="12:00"
                required
                onChange={handleChange}
            />
            <input
                type="number"
                placeholder="Взрослые билеты"
                name="adults"
                min="0"
                required
                onChange={handleChange}
            />
            <input
                type="number"
                name="children"
                placeholder="Детские билеты"
                min="0"
                required
                onChange={handleChange}
            />
            <Button text="Купить билеты" backgroundColor="#FFCF08"/>
        </form>
    );
};

const EventDetails: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [event, setEvent] = useState<Event | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const host = 'https://api.ins-russpass.ru';
                const response = await axios.get<Event>(`${host}/trips/${id}`);
                setEvent(response.data);
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchData().then()
    }, [id]);

    const handleSubmit = (formData: any) => {
        navigate('/order', {state: {formData, eventId: event?.id}});
    };

    if (!event) {
        return <div>Loading...</div>;
    }

    return (
        <div className="event_details">
            <Navbar/>
            <div className="event_details_text">
                <p className='bread'>Главная / События / {event.name}</p>
                <img src={`https://api.ins-russpass.ru${event.images[0]}`} alt="Background" className='preview'/>
                <h1>{event.name}</h1>
                <h3>{event.location}</h3>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginLeft: "20px",
                marginBottom: "20px"
            }}>
                <p><strong><MdEvent/></strong> Выставка</p>
                <p><strong><MdDateRange/></strong> 10 января — 31 декабря</p>
                <p><strong><MdLocationOn/></strong> ул. Большая Дмитровка, д. 6, стр. 8</p>
                <p><strong><MdPlace/></strong> ГМИИ им. А.С. Пушкина</p>
                <p><strong><MdAccessTime/></strong> Сегодня: 10:00-19:00</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row',}}>
                <Button text="Аудиогид" width="110px" backgroundColor="white" border="1px solid #007470"
                        textAlign="center"/>
                <Button text="Повышенные бонусы" width="220px" backgroundColor="white" border="1px solid #007470"
                        textAlign="center"/>
            </div>
            <Button text="Выбрать билеты" width="380px" backgroundColor="#FFCF08" textAlign="center"/>
            <div className="event_details_text event_details_text2">
                <br/>
                <p>{event.description}</p>
                <br/>
            </div>
            <EventForm eventId={event.id} onSubmit={handleSubmit}/>
            <Slider/>
            <div className="img">
                <div className="ins event_additional">
                    <Ins/>
                </div>
                <div className="audio event_additional">
                    <Audio/>
                </div>
                <div className="kreml event_additional">
                    <Kreml/>
                </div>
                <div className="exc event_additional">
                    <Exc/>
                </div>
                <div className="rait event_additional">
                    <Rait/>
                </div>
                <div className="interes event_additional">
                    <Interes/>
                </div>
            </div>

            <Footer/>
        </div>
    );
};


export default EventDetails;

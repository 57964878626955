import React, {useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Button from "../../UI/Button";
import logo from './img/success_icon.svg';
import './PaymentSuccess.css';

const PaymentSuccess: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const access = localStorage.getItem('access');
        if (!access) {
            navigate('/events/1');
        }
    }, []);

    const handleButtonClick = () => {
        navigate('/profile');
    }

    return (
        <div>
            <Navbar/>
            <div className='div_success'>
                <img src={logo}/>
                <h2>Успешная оплата!</h2>
                <Button text={`К заказу!`} width="70%" backgroundColor="#FFCF08" onClick={handleButtonClick}/>
            </div>
            <Footer/>
        </div>
    );
};

export default PaymentSuccess;
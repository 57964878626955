import React from 'react';
import cl from './Banner.module.css'; // Предполагается, что стили будут определены в этом файле CSS
import {useNavigate} from "react-router-dom";

type BannerProps = {
    onInsuranceClick: () => void; // Обработчик нажатия кнопки страховки
};


const Banner: React.FC = () => {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate('/landing'); // Переходим на страницу landing при нажатии на кнопку
    };
    return (
        <div className={cl.banner_container}>
            <img className={cl.banner_img} src="./img/banner.svg" alt=""/>
            <div className={cl.banner_content}>
                <div className={cl.banner_text}>
                    <h1 className={cl.banner_title}>Обезопасьте себя <br/>от непредвиденных ситуаций</h1>
                    <p className={cl.banner_description}>Оформите страховку в 1 клик и мы возместим деньги за билеты при
                        отмене</p>
                </div>
                <div className={cl.button}>
                    <button className={cl.banner_button} onClick={handleButtonClick}>
                        Зачем мне страховка?
                    </button>
                </div>

            </div>
        </div>
    );
};

export default Banner;

import React from 'react';
import cl from "./TicketInProfile.module.css";
import Navbar from "../../components/navbar/Navbar";
import { ReactComponent as Path } from "./img/path.svg";
import { ReactComponent as Profile } from "./img/profile.svg";
import { ReactComponent as Back } from "./img/back.svg";
import { ReactComponent as Place } from "./img/place.svg";
import { ReactComponent as Button1 } from "./img/button1.svg";
import { ReactComponent as Button2 } from "./img/button2.svg";
import { ReactComponent as Button3 } from "./img/button3.svg";
import { ReactComponent as Pasport } from "./img/pasport.svg";
import { ReactComponent as Ticket1 } from "./img/ticket1.svg";
import { ReactComponent as Ticket2 } from "./img/ticket2.svg";
import { ReactComponent as Ticket3 } from "./img/ticket3.svg";
import { ReactComponent as Contacts } from "./img/contacts.svg";
import { ReactComponent as Info } from "./img/info.svg";
import { ReactComponent as Feedback } from "./img/feedback.svg";
import { ReactComponent as ButtonExc } from "./img/buttonExc.svg";
import { ReactComponent as Footer } from "./img/footer.svg";

const TicketInProfile = () => {
    return (
        <div className={cl.ticketInProfile}>
                <div className={cl.navbar}>
                        <Navbar/>
                </div>
                <div className={cl.path}>
                    <Path/>
                </div>
                <div className={cl.profile}>
                    <Profile/>
                </div>
                <div className={cl.back}>
                    <Back/>
                </div>
                <div className={cl.places}>
                    <Place/>
                </div>
                <div className={cl.buttons}>
                        <div className={cl.button}>
                                <Button1/>
                        </div>
                        <div className={cl.button}>
                                <Button2/>
                        </div>
                        <div className={cl.button}>
                                <Button3/>
                        </div>
                </div>
                <div className={cl.pasport}>
                    <Pasport/>
                </div>
                <div className={cl.ticket}>
                    <Ticket1/>
                </div>
                <div className={cl.ticket}>
                    <Ticket2/>
                </div>
                <div className={cl.ticket}>
                    <Ticket3/>
                </div>
                <div className={cl.contacts}>
                    <Contacts/>
                </div>
                <div className={cl.info}>
                    <Info/>
                </div>
                <div className={cl.feedback}>
                    <Feedback/>
                </div>
                <div className={cl.buttonExc}>
                    <ButtonExc/>
                </div>
                <div className={cl.footer}>
                    <Footer/>
                </div>
        </div>
    );
};

export default TicketInProfile;
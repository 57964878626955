import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import InsuranceSections from './components/insuranceSection/InsuranceSection'
import './Landing.css';
import WhySections from "./components/WhySection";
import Steps from "./components/Steps";
import { ReactComponent as Wave } from './img/wave.svg';
import FAQComponent from "../../components/FAQ/FAQ";
import Footer from "../../components/footer/Footer";
import Excursions from "../eventDetails/Events";

const Landing = () => {
    return (
        <div className="container">
            <Navbar/>
            <Wave className="insurance-wave"/>
            <div className="insurance">
                <InsuranceSections/>
                <WhySections/>
                <Steps/>
                <FAQComponent/>
            </div>
            <Footer/>
        </div>
    );
};

export default Landing;
import React, {MouseEventHandler, useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import cl from './Events.module.css';
import Button from '../../UI/Button';


// Определите интерфейс Excursion для вашего ответа.
interface Excursion {
    id: number;
    name: string;
    location: string;
    images: string;
}

const Events = () => {
    const navigate = useNavigate();
    const [excursion, setExcursion] = useState<Excursion[]>([]);

    useEffect(() => {
        async function fetchData() {
            const host = 'https://api.ins-russpass.ru'
            try {
                const response = await axios.get<Excursion[]>(`${host}/trips`)
                const excursion = response.data.map(exc => ({ ...exc }))
                setExcursion(response.data);
            } catch (error) {
                console.error('Ошибка при запросе:', error);
            }
        }

        fetchData();
    }, []);

    const handleClick = (id: number) => {
        navigate(`/events/${id}`);
    };

    // Рендеринг данных или другие действия с состоянием excursion
    return (
        <div className={cl.events}>
            <h1 className={cl.header1}>Места и события</h1>
            <div className={cl.events_scroll}>
                {excursion.map(exc => (
                    <div className={cl.event_item} key={exc.id} onClick={() => handleClick(exc.id)}>
                        <img src={`https://api.ins-russpass.ru${exc.images}`} alt="Background" className={cl.backgroundImage}/>
                        <div className={cl.event_text}>
                            <h3>{exc.name}</h3>
                            <p>{exc.location}</p>
                        </div>
                        <Button text="Купить билеты" width="80%" backgroundColor="#FFCF08"/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Events;

import React from 'react';
import cl from "../../pages/main/Main2.module.css";
import { ReactComponent as PlansText } from "./planstext.svg";
import { ReactComponent as Search } from "./search.svg";

const SearchField = () => {
    return (
        <div className={cl.plans}>
            <div className={cl.plans_text}>
                <PlansText/>
            </div>
            <div className={cl.plans_search}>
                <Search/>
            </div>
        </div>
    );
};

export default SearchField;
import React, { useState } from 'react';
import axios from 'axios';
import Button from "../../UI/Button";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

interface FormState {
    email: string;
    password: string;
}

const SignIn: React.FC = () => {
    const [formData, setFormData] = useState<FormState>({ email: 'novikovglebka@gmail.com', password: '1234' });
    const [error, setError] = useState<string | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://api.ins-russpass.ru/auth/jwt/create', formData);
            console.log('Authentication successful');
            localStorage.setItem('access', response.data.access);
            window.open('/', '_self')
        } catch (error) {
            console.log('Authentication failed');
            setError('Authentication failed');
        }
    };

    return (
        <div>
            <Navbar/>
            <h1 style={{textAlign: "center", marginTop:"150px", marginBottom:"20px"}}>Вход</h1>
            {error && <p>{error}</p>}
            <form style={{marginBottom:"150px"}} onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Пароль"
                    required
                    onChange={handleChange}
                />
                <Button text="Войти" backgroundColor="#FFCF08"/>
            </form>
            <Footer/>
        </div>
    );
};

export default SignIn;
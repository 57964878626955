import React from 'react';
import step from '../img/step.svg';
import cl from './Steps.module.css';

const stepData = [
    {
        step: 'Шаг 1.',
        title: 'Оформите страховку при покупке билета',
        content: 'Стоимость не более 10% от билета. Например, при покупке билета на балет в Мариинском театре, страховка составит всего 220 рублей',
        img: step,
    },
    {
        step: 'Шаг 2.',
        title: 'Подайте заявку в личном кабинете',

    },
    {
        step: 'Шаг 3.',
        title: 'Отследите статус заявки в личном кабинете и получите выплату',
    }
];


interface StepSectionProps {
    step: string;
    title: string;
    content?: string;
    img?: string;
}

export const Step: React.FC<StepSectionProps> = ({step, title, content, img}) => (
    <div className={cl.step_item}>
        <div className={cl.step_text}>
            <h2>{step}</h2>
            <h2>{title}</h2>
            {content && <p>{content}</p>}
        </div>
        {img && <img className={cl.step_img} src={img} alt="img"/>}
    </div>
);

const Steps: React.FC = () => {
    return (
        <div className={cl.steps}>
            <div className={cl.steps_text}>
                <h2>Как воспользоваться страховкой?</h2>
            </div>
            <div className={cl.steps_content}>
                {stepData.map((section, index) => (
                    <Step key={index} step={section.step} title={section.title} content={section.content}
                          img={section.img}/>
                ))}
            </div>
        </div>
    );
};

export default Steps;
import img1 from '../img/img1.svg';
import img2 from '../img/img2.svg';
import img3 from '../img/img3.svg';

export const dataWhy = {
    sections: [
        {
            title: 'Бесплатный возврат билетов',
            content: 'Мы вернём вам полную стоимость билетов в случае возникновения форс-мажорных ситуаций.',
            img: img1,
        },
        {
            title: 'Простой процесс возврата',
            content: 'Заявка на возврат билетов отправляется электронно в личном кабинете RUSSPASS.',
            img: img2,
        },
        {
            title: 'Стоимость не более 10% от билета',
            content: 'Выгодное приобретение перед мероприятием или путешествием, чтобы чувствовать что у вас всё под контролем.',
            img: img3,
        },
    ]
};
export const data = {
    sections: [
        {
            title: 'Оформление страховки:',
            content: 'Страховка оформляется при онлайн-бронировании билетов и подтверждается отметкой о согласии с условиями оферты.'
        },
        {
            title: 'Страховой тариф:',
            content: 'Размер страховой премии зависит от стоимости билетов и составляет определенный процент от их цены.'
        },
        {
            title: 'Вступление в действие:',
            content: 'Договор страхования вступает в силу с момента уплаты страховой премии и действует до завершения страхового периода.'
        },
        {
            title: 'Страховое покрытие:',
            content: 'Страховка обеспечивает финансовую защиту от определенных рисков, таких как невозможность посещения мероприятия по различным причинам, включая отмену или перенос.'
        }
    ]
};
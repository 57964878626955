import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as Logo} from './img/logo.svg';
import {ReactComponent as Russia} from './img/logo-russia.svg';
import {ReactComponent as Menu} from './img/menu.svg';
import {ReactComponent as User} from './img/user.svg';
import {ReactComponent as Down} from './img/chevron-down.svg';
import {ReactComponent as Mosturizm} from './img/mosturizm.svg';
import {ReactComponent as Heart} from './img/heart.svg';
import {ReactComponent as Bonus} from './img/bonus.svg';
import {ReactComponent as Flags} from './img/flags.svg';
import './Navbar.css';

const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem('access');
        setIsAuth(!!token);
    }, []);

    const handleSignInClick = () => {
        navigate('/sign-in');
    }

    const handleProfileClick = () => {
        navigate('/profile');
    }

    const handleEventClick = () => {
        navigate('/events/1'); // Переход на страницу мероприятия
    }

    return (
        <nav className="navbar">
            <div className="navbar_left" onClick={handleEventClick}>
                <Logo/>
                <div className="header_line"></div>
                <div className="russia_down">
                    <Russia/>
                    <Down/>
                </div>
                <div className="navbar__link menu-link">
                    <Menu/>
                    <span className="link_text">Меню</span>
                </div>
                <div className="navbar__link bonus-link">
                    <Bonus/>
                    <span className="link_text">Бонусы</span>
                </div>
            </div>
            <div className="navbar_right">
                <div className="navbar__link">
                    <Mosturizm/>
                    <span className="link_text">Проекты Мостуризма</span>
                </div>
                <div className="navbar__link">
                    <Heart/>
                    <span className="link_text">Мои планы</span>
                </div>
                <div className="navbar__link" onClick={isAuth ? handleProfileClick : handleSignInClick}>
                    <User/>
                    <span className="link_text">{isAuth ? 'Профиль' : 'Войти'}</span>
                </div>
                <Flags className="flags-icon"/>
            </div>
        </nav>
    );
};

export default Navbar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cl from './FAQ.module.css';

interface FAQ{
    question: string;
    answer: string;
    isOpen: boolean;
}

function FAQComponent() {
    const [faqs, setFaqs] = useState<FAQ[]>([]);

    useEffect(() => {
        async function fetchData() {
            const mockServerUrl = "https://3e60cc24-e9ac-4e60-b9db-90327214b36f.mock.pstmn.io/faq";
            try {
                const response = await axios.get<{ faq: FAQ[] }>(mockServerUrl);
                // Инициализируем все элементы как закрытые
                const faqsData = response.data.faq.map(faq => ({ ...faq, isOpen: false }));
                setFaqs(faqsData);
            } catch (error) {
                console.error('Ошибка при запросе:', error);
            }
        }

        fetchData();
    }, []);

    // Функция для изменения состояния isOpen для конкретного FAQ
    const toggleFAQ = (index: number) => {
        setFaqs(faqs.map((faq, i) => {
            return i === index ? { ...faq, isOpen: !faq.isOpen } : { ...faq, isOpen: false };
        }));
    };

    return (
        <div className={cl.faq_container}>
            <h2 style={{fontSize: "30px"}}>Часто задаваемые вопросы</h2>
            <div className={cl.faq_content}>
                {faqs.map((faq, index) => (
                    <div
                        className={`${cl.faq_item} ${faq.isOpen ? 'open' : ''}`}
                        key={index}
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className={cl.faq_question}>
                            {faq.question}
                            <span className={cl.faq_toggle}>{faq.isOpen ? '−' : '+'}</span>
                        </div>
                        <div className={cl.faq_answer} style={{ display: faq.isOpen ? 'block' : 'none' }}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQComponent;

import React from 'react';
import { dataWhy } from './dataWhy';
import cl from './WhyIns.module.css';
import Slider from './Slider';

interface WhySectionProps {
    title: string;
    content: string;
    img: string;
}

export const Section: React.FC<WhySectionProps> = ({ title, content, img}) => (
    <div className={cl.why_item}>
        <img className={cl.why_img} src={img} alt="img" />
        <div className={cl.why_item_text}>
            <h2>{title}</h2>
            <p>{content}</p>
        </div>
    </div>
);

const WhySections: React.FC = () => (
    <div className={cl.why_container}>
        <div className={cl.why_text}>
            <h2>Зачем мне страховка?</h2>
        </div>
        <div className={cl.why_content}>
            <div className={cl.why_section}>
                {dataWhy.sections.map((section, index) => (
                    <Section key={index} title={section.title} content={section.content} img={section.img}/>
                ))}
            </div>
            <Slider/>
        </div>

    </div>
);

export default WhySections;

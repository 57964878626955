import React from 'react';
import Events from "../eventDetails/Events";
import Navbar from "../../components/navbar/Navbar";
import Plans from "./components/Plans";
import './Main.css';
import Banner from "../../components/banner/Banner";
import Button from "../../UI/Button";
import SearchField from "../../components/searchField/SearchField";
import Footer from "../../components/footer/Footer";


const Main: React.FC = () => {
    return (
        <div className="main">
            <Navbar/>
            <SearchField/>
            <Events/>
            <div className="button_container">
                <Button text="Посмотреть все" width="100%" backgroundColor="background-color: #EBEBEB;" margin="0 16px 32px"/>
            </div>

            <Banner/>
            <Footer/>

            {/*<Navbar/>*/}
            {/*<Plans/>*/}
        </div>
    );
};

export default Main;
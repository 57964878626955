import React from 'react';
import { data } from '../data';
import cl from './InsuranceSection.module.css';

interface SectionProps {
    title: string;
    content: string;
}

export const Section: React.FC<SectionProps> = ({ title, content }) => (
    <div className={cl.section}>
        <h2>{title}</h2>
        <p>{content}</p>
    </div>
);

const InsuranceSections: React.FC = () => (
    <div className={cl.insurance_container}>
        <div className={cl.insurance_content}>
            <div className={cl.insurance_text}>
                <h1>Страховка билетов</h1>
            </div>
            <div className={cl.insurance_sections}>
                {data.sections.map((section, index) => (
                    <Section key={index} title={section.title} content={section.content} />
                ))}
            </div>
        </div>
    </div>
);

export default InsuranceSections;

import React from 'react';
import cl from "./Button.module.css";

type ButtonProps = {
    text: string;
    width?: string;
    backgroundColor?: string;
    margin?: string;
    border?: string;
    textAlign?: "left" | "right" | "center" | "justify" | "initial" | "inherit";
    onClick?: () => void;
}
const Button: React.FC<ButtonProps> = ({text, width, backgroundColor, margin, border, textAlign, onClick}) => {
    return (
        <button className={cl.button} style={{width: width, backgroundColor: backgroundColor, margin: margin, border: border, textAlign: textAlign}} onClick={onClick}>
            {text}
        </button>
    );
};

export default Button;
import React, {useState, useEffect} from 'react';
import { dataCases } from "./dataCases";
import cl from './Slider.module.css';
import { useSwipeable } from 'react-swipeable';
import  { useSpring, animated } from 'react-spring';

type SliderProps = {
    child?: boolean
}

const Slider: React.FC<SliderProps> = ({child}) => {
    const [text, setText] = useState(dataCases[0].description);
    const [caseIndex, setCaseIndex] = useState(0);
    let filterCases = dataCases.filter(obj => !obj.child).map(obj => (obj));
    if (child) {
        dataCases.forEach(function (value) {
            if (value.child) {
                filterCases.unshift(value);
            }
        });
    }

    const [props, set] = useSpring(() => ({ opacity: 1, transform: 'translateX(0px)', config: { tension: 280, friction: 60} }))

    const onClickNext = () => {
        set({ opacity: 0.5});
        setTimeout(() => {
            setCaseIndex((prevIndex) => (prevIndex + 1) % filterCases.length);
            setText(filterCases[caseIndex].description);
            set({ opacity: 1, transform: 'translateX(0px)' });
        }, 500);
    }

    const onClickPrev = () => {
        set({ opacity: 0.5});
        setTimeout(() => {
            setCaseIndex((prevIndex) => (prevIndex - 1 + filterCases.length) % filterCases.length);
            setText(filterCases[caseIndex].description);
            set({ opacity: 1, transform: 'translateX(0px)' });
        }, 500);
    }

    const handlers = useSwipeable({
        onSwipedLeft: onClickNext,
        onSwipedRight: onClickPrev,
        trackMouse: true
    });

    useEffect(() => {
        setText(filterCases[caseIndex].description);
    }, [caseIndex]);

    return (
            <animated.div className={cl.slider} style={props} {...handlers}>
            <h2>{filterCases[caseIndex].title}</h2>
            <div className={cl.slider_content}>
                <div className={cl.slider_text}>
                    <p>{text}</p>
                </div>
            </div>
            <img src={filterCases[caseIndex].imageUrl} alt="case"/>
            <div className={cl.slider_buttons}>
                <button className={cl.slider_button} onClick={onClickPrev}>&lt;</button>
                <span>{caseIndex+1}/{filterCases.length}</span>
                <button className={cl.slider_button} onClick={onClickNext}>&gt;</button>
            </div>
        </animated.div>
    );
};

export default Slider;
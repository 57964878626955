import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Main from './pages/main/Main';
import './styles/common.css';
import EventDetails from "./pages/eventDetails/EventDetails";
import Order from "./pages/order/Order";
import Profile from "./pages/profile/Profile";
import SignIn from "./pages/sign-in/Sign-in";
import Landing from "./pages/landing/Landing";
import PaymentSuccess from "./pages/paymentSuccess/PaymentSuccess";
import Place from "./pages/place/Place";
import Tickets from "./pages/tickets/Tickets";
import Insurance from "./pages/insurance/Insurance";
import TicketInProfile from "./pages/ticketInProfile/TicketInProfile";


function App() {
  return (
    <div className="App">
        <Router>
            <Routes>
                <Route path="/" element={<Main/>} />
                <Route path="/event" element={<Place/>} />
                <Route path="/tickets" element={<Tickets/>} />
                <Route path="/ticketProfile" element={<TicketInProfile/>} />
                <Route path="/events/:id" element={<EventDetails/>} />
                <Route path="/order" element={<Order/>} />
                <Route path="/profile" element={<Profile/>} />
                <Route path="/sign-in" element={<SignIn/>} />
                <Route path="/landing" element={<Landing/>} />
                <Route path="/success" element={<PaymentSuccess/>} />
                <Route path="/insurance/:id" element={<Insurance/>} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;



import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import Profile from "../profile/Profile";
import Order from "../order/Order";
import Navbar from "../../components/navbar/Navbar";
import StepComponent from './StepComponent';
import Footer from "../../components/footer/Footer";
import "./Insurance.css";

// import TicketSelector from "./TicketSelector";

interface Refund {
    tickets: number[];
    reason: string;
    comment: string;
    bonus_refund: boolean;
}

const Insurance: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [profile, setProfile] = useState<Profile | null>(null);
    const [order, setOrder] = useState<Order>();
    const [reason, setReason] = useState('');

    const getProfile = async () => {
        const host = 'https://api.ins-russpass.ru'
        const access = localStorage.getItem('access')
        const response = await axios.get<Profile>(`${host}/users/me`,
            {headers: {"Authorization": `Bearer ${access}`}});
        setProfile(response.data);
    };
    const getOrder = async () => {
        const host = 'https://api.ins-russpass.ru';
        const access = localStorage.getItem('access');
        const response = await axios.get<Order>(`${host}/payments/${id}`,
            {headers: {"Authorization": `Bearer ${access}`}});
        setOrder(response.data);
    };
    const postInsurance = async () => {
        if (order?.tickets == null) return;
        const insurance: Refund = {
            tickets: order?.tickets.map(value => Number(value.id)),
            reason: reason,
            comment: reason,
            bonus_refund: false
        }
        const access = localStorage.getItem('access');
        const host = 'https://api.ins-russpass.ru';
        const response = await axios.post<Refund>(`${host}/payments/${id}/refund`,
            insurance, {headers: {"Authorization": `Bearer ${access}`}});
        window.location.reload();
    }

    useEffect(() => {
        getProfile();
        getOrder();
    }, [id]);

    const steps = order?.refund == null ? [
        {label: 'Подача заявки', isCompleted: true},
        {label: 'Рассмотрение заявки', isCompleted: false},
        {label: 'Возврат средств', isCompleted: false}

    ] : [
        {label: 'Подача заявки', isCompleted: true},
        {label: 'Рассмотрение заявки', isCompleted: order?.refund?.status != null},
        {
            label: order?.refund?.status == 'canceled' ? 'Отказано' : "Возврат  оформлен",
            isCompleted: order?.refund?.status == 'success' || order?.refund?.status == 'canceled'
        }
    ];

    console.log(order?.tickets);

    return (
        <div>
            <Navbar/>
            <div className='home'>
                <h1 className='t1'>Возврат</h1>
                <StepComponent steps={steps}/>
                {/*<p>Заявка ({(order?.refund != null) ? 'подана' : 'не подана'})</p>*/}
                {order?.refund != null ?
                    <p>Статус: {order.refund.status}</p> :
                    <>
                        <input value={reason} placeholder={'Причина возврата'}
                               onChange={e => setReason(e.target.value)}/>
                        <input type='file'/>
                        <br/>
                        <label>
                            <input type={'checkbox'} onChange={e => console.log(e.target.checked)}/>
                            Возврат бонусами
                        </label>
                        <br/>
                        <button onClick={postInsurance} disabled={order?.refund != null}>Подать заявку</button>
                    </>
                }
                {/*<TicketSelector tickets={order?.tickets}/>*/}
            </div>
            <Footer/>
        </div>
    )
}

export default Insurance;

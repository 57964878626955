import React from 'react';
import cl from "./Place.module.css";
import Navbar from "../../components/navbar/Navbar";
import { ReactComponent as Path } from "./img/path.svg";
import { ReactComponent as Event } from "./img/place.svg";
import { ReactComponent as Title } from "./img/title.svg";
import { ReactComponent as Choose } from "./img/choose.svg";
import { ReactComponent as About } from "./img/about.svg";
import { ReactComponent as ByTicket } from "./img/byTIcket.svg";
import { ReactComponent as Insurance } from "./img/insurance.svg";
import { ReactComponent as Audio } from "./img/audio.svg";
import { ReactComponent as Kreml } from "./img/kreml.svg";
import { ReactComponent as Advert } from "./img/advert.svg";
import { ReactComponent as Feedback } from "./img/feedback.svg";
import { ReactComponent as Map } from "./img/map.svg";
import { ReactComponent as Interesting } from "./img/interesting.svg";
import { ReactComponent as Footer } from "./img/footer.svg";


const Place = () => {
    return (
        <div className={cl.place}>
                <div className={cl.navbar}>
                        <Navbar/>
                </div>
                <div className={cl.path}>
                    <Path/>
                </div>
                <div className={cl.places}>
                    <Event/>
                </div>
                <div className={cl.title}>
                    <Title/>
                </div>
                <div className={cl.choose}>
                    <Choose/>
                </div>
                <div className={cl.about}>
                    <About/>
                </div>
                <div className={cl.byTicket}>
                    <ByTicket/>
                </div>
                <div className={cl.insurance}>
                    <Insurance/>
                </div>
                <div className={cl.audio}>
                    <Audio/>
                </div>
                <div className={cl.kreml}>
                    <Kreml/>
                </div>
                <div className={cl.advert}>
                    <Advert/>
                </div>
                <div className={cl.feedback}>
                    <Feedback/>
                </div>
                <div className={cl.map}>
                    <Map/>
                </div>
                <div className={cl.interesting}>
                    <Interesting/>
                </div>
                <div className={cl.footer}>
                    <Footer/>
                </div>
        </div>
    );
};

export default Place;